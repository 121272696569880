.bm-burger-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 0;
  top: 26px;
  left: 20px;
  color: #fff;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item-list {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: none;
}

@media (min-width: 1650px) {
  .wrapper {
    display: flex;
  }

  .bm-burger-button {
    visibility: hidden;
  }
}
